<script>
import PopupGift from '@/components/popupGift/index.vue'
import {nextTick} from "vue"
import axios from "axios"
export default {
	name: "Giftform",
	props:{
		prizeId:{
			type: String,
		},
	},
	computed: {
		cities(){
			return this.$store.state.addressData;
		},
		areas(){
			return this.cities[this.userInputInfo.citiesIndex].areas;
		},
		prizesList(){
			return this.$store.state.prizesList
		},
		allPoints(){
			return this.$store.state.allPoints
		},
		hostUrl(){
			return this.$store.state.hostUrl;
		},
		token(){
			return this.$store.state.token;
		},
	},
	data(){
		return{
			userInputInfo:{
				citiesIndex: 0,
				areasIndex: 0,
				name:"",
				phone:"",
				email:"",
				addressDetail:"",
				address:"",
				prize:""
			},
			errorClass: {},
			isActivePopupGift: false,
			isSendSucess: false,
			prizeData: {},
			isLock: false
		}
	},
	components:{
		PopupGift
	},
	mounted() {
		nextTick(()=>{
			let vm = this;
			vm.getPrizeData()
		})
	},
	methods: {
		getPrizeData(){
			// console.log("getPrizeData")
			let vm=this
			vm.prizesList.forEach(element => {
				if(element.id===Number(vm.prizeId)){
					vm.prizeData=element
				}
			});
		},
		checkInput(){
			// console.log("checkInput")
			let vm=this

			if (vm.userInputInfo.name === "") {
				vm.errorClass.name=true
			}else{
				vm.errorClass.name=false
			}

			let validPhone = /^09\d{8}$/;
			if (vm.userInputInfo.phone === ""||!validPhone.test(vm.userInputInfo.phone)) {
				vm.errorClass.phone=true
			}else {
				vm.errorClass.phone=false
			}

			let validEmail = /[a-zA-Z0-9.]*@[a-z]*[.a-z]*/;
			if (vm.userInputInfo.email === ""||!validEmail.test(vm.userInputInfo.email)) {
				vm.errorClass.email=true
			}else {
				vm.errorClass.email=false
			}

			if (vm.userInputInfo.citiesIndex === 0) {
				vm.errorClass.citiesIndex=true
				vm.errorClass.areasIndex=true
			}else {
				vm.errorClass.citiesIndex=false
				vm.errorClass.areasIndex=false
				vm.userInputInfo.address = vm.cities[vm.userInputInfo.citiesIndex].name + vm.cities[vm.userInputInfo.citiesIndex].areas[vm.userInputInfo.areasIndex].name + vm.userInputInfo.addressDetail;
			}

			if (vm.userInputInfo.addressDetail === "") {
				vm.errorClass.addressDetail=true
			}else{
				vm.errorClass.addressDetail=false
			}

			let array = Object.keys(vm.errorClass)
				.map(function(key) {
					return vm.errorClass[key];
				});

			if(array.indexOf(true)!==-1){
				return
			}

			vm.sendForm();
		},
		sendForm(){
			console.log("sendForm")
			let vm = this;
			
			vm.$store.commit('loadingToggle',true)
			vm.isLock=true	

			let data={
				prize_id: vm.prizeId,
				name: vm.userInputInfo.name,
				mobile: vm.userInputInfo.phone,
				email: vm.userInputInfo.email,
				address: vm.userInputInfo.address,
			}

			axios.post(vm.hostUrl+"api/user/exchange",data,
				{
					headers: {
						"Authorization": `Bearer ${vm.token}`,
						"Accept": "application/json",
					},
				})
				.then((response)=>{
					console.log(response)
					if(response.data.success){
						vm.$store.dispatch("getUserNTInfo").then(()=> {
							setTimeout(() => {
								vm.isActivePopupGift=true
								vm.isLock=false
								vm.isSendSucess=true
								vm.$store.commit('loadingToggle',false)
							}, 2500);
						})
					}
				})
				.catch(function (error) {
					alert(error.response.data.error.message)
					vm.$store.commit('loadingToggle',false)
					vm.isLock=false
				});
		},
	},
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>