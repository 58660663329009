<script>
export default {
	name: "PopupGift",
	props:{
		isActivePopupGift:{
			type: Boolean,
			required: true,
		},
		isSendLottery:{
			type: Boolean,
		},
		isSendSucess:{
			type: Boolean,
			required: true,
		},
		type:{
			type: String,
		},
		isLock:{
			type: Boolean,
		}
	},
	data(){
		return{
		}
	},
	methods: {
		closePopup(target){
			// console.log("closePopup")
			let vm=this
			if(target==="send"){
				vm.$emit("update:isSendLottery", true);
			}else{
				vm.$emit("update:isActivePopupGift", false);
			}
		},
	},
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>